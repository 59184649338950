import React, { useCallback, useMemo } from 'react';

import { useApplyProvider } from 'V2/Apply';

import { DesignVersioningContext } from './DesignVersioningContext';
import { State, Step, Version, VersioningConfig } from './types';

const isFunction = (value: unknown): value is Function =>
  typeof value === 'function';

const DESIGN_VERSIONS_BY_STATE: VersioningConfig = {
  IL: {
    confirm_quote: (nsa: boolean) => ({
      buy_now_button: nsa ? 'V2' : 'default',
      policy_section: nsa ? 'V2' : 'default',
      price_section: nsa ? 'V2' : 'default',
    }),
  },
  default: {
    confirm_quote: {
      buy_now_button: 'default',
      policy_section: 'default',
      price_section: 'default',
    },
  },
};

export const DesignVersioningProvider: React.FC = (props) => {
  const { children } = props;

  const {
    app: {
      status: { id: stepName },
      config: {
        policy_enforced_state: policyEnforcedState,
        features_by_key: {
          product_segment: productSegment,
          product_type: productType,
        },
      },
    },
  } = useApplyProvider();

  const isNonStandardAutoProduct =
    productSegment === 'nonStandardAuto' && productType === 'personal_auto';

  /**
   * The `overrideStepName` prop is introduced to ensure that the correct step version
   * is displayed when transitioning between steps. This is necessary because the Step components
   * are loaded lazily, and the value of `stepName` is derived from the response of the `/status` endpoint.
   *
   * Since the new step's component is loaded lazily after receiving the `stepName` from the endpoint,
   * there is a small delay during which a different step component might be shown.
   * By allowing `overrideStepName` to be passed explicitly, we ensure that the correct version
   * is displayed immediately, avoiding any temporary mismatch between the displayed step and the actual
   * step as determined by the backend.
   */
  const getSectionVersion = useCallback(
    (sectionName: string, overrideStepName?: Step): Version => {
      const stepNameToUse = overrideStepName || stepName;

      const stateConfig =
        DESIGN_VERSIONS_BY_STATE[policyEnforcedState as State] ||
        DESIGN_VERSIONS_BY_STATE['default'];
      const stepConfig = stateConfig[stepNameToUse as Step];

      if (isFunction(stepConfig)) {
        return stepConfig(isNonStandardAutoProduct)[sectionName] || 'default';
      }

      if (!stepConfig) {
        return 'default';
      }

      return stepConfig[sectionName] || 'default';
    },
    [isNonStandardAutoProduct, stepName, policyEnforcedState]
  );

  const value = useMemo(() => ({ getSectionVersion }), [getSectionVersion]);

  return (
    <DesignVersioningContext.Provider value={value}>
      {children}
    </DesignVersioningContext.Provider>
  );
};
